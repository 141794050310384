<template>
  <footer>
    <p>©{{ thisYear }} — Fit Formulation</p>
  </footer>
</template>

<script>
import moment from "moment";
export default {
  name: "Footer",
  data() {
    return {
      thisYear: moment().format("YYYY"),
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
