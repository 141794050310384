import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue';
import Consultation from '../views/Consultation.vue';
const routes = [
  {
    path: '/',
    name: 'default',
    component: Login,
    meta: {
      reload: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      reload: true,
    },
  },
  {
    path: '/switchuser/:token',
    name: 'SwitchToUser',
    component: () => import('../views/SwitchToUser.vue'),
  },
  {
    path: '/enrollment',
    name: 'Enrollment',
    component: () => import('../views/patient/Enrollment.vue'),
    meta: {
      reload: true,
    },
  },
  {
    path: '/testpayment',
    name: 'TestPayment',
    component: () => import('../views/patient/TestPayment.vue'),
    meta: {
      reload: true,
    },
  },
  {
    path: '/thanks/:transaction_id',
    name: 'Thanks',
    component: () => import('../views/patient/Thanks.vue'),
    meta: {
      reload: true,
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/patient/PrivacyPolicy.vue'),
    meta: {
      reload: true,
    },
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/enrollment/Test.vue'),
  },
  {
    path: '/conference',
    name: 'Consultation',
    component: Consultation,

  },
  {
    path: '/enrollment/manager/dashboard',
    name: 'EnrollmentManagerDashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/manager/EnrollmentManagerDashboard.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/enrollment/manager/faqlist',
    name: 'FaqList',
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/manager/FaqList.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/enrollment/manager/faqcreate',
    name: 'FaqCreate',
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/manager/FaqCreate.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/enrollment/manager/faqedit/:uid',
    name: 'FaqEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/manager/FaqEdit.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/enrollment/manager/allpatient',
    name: 'AllPatientList',
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/manager/AllPatientList.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/enrollment/manager/billingverification',
    name: 'BillingVerificationPatient',
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/manager/BillingVerificationPatient.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/enrollment/manager/rxbillingconfirmation',
    name: 'RXBillingConfirmation',
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/manager/RXBillingConfirmation.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/enrollment/manager/notes',
    name: 'PatientNotes',
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/manager/PatientNotes.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/enrollment/manager/patientview/:uid',
    name: 'PatientInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/manager/PatientInfo.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/enrollment/manager/profile',
    name: 'EnrollmentManagerProfile',
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/manager/EnrollmentManagerProfile.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: "/enrollment/manager/create/agent",
    name: "EnrollmentManagerCreateAgent",
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/manager/EnrollmentAgentForm.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/enrollment/manager/agentedit/:user_uid",
    name: "EnrollmentManagerEditAgent",
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/manager/EnrollmentAgentForm.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/enrollment/manager/agentlist",
    name: "EnrollmentManagerAgentList",
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/manager/EnrollmentAgentList.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: '/enrollment/manager/extension',
    name: 'EnrollmentManagerAssignExtension',
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/manager/AssignExtension.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: '/enrollment/form',
    name: 'enrollmentform',
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/agent/EnrollmentForm.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/enrollment/list',
    name: 'enrollmentlist',
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/agent/EnrollmentList.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/enrollment/formedit/:uid',
    name: 'EnrollmentFormEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/agent/EnrollmentFormEdit.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/enrollment/formview/:uid',
    name: 'EnrollmentFormView',
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/agent/EnrollmentFormView.vue'),
    meta: {
      allowAnonymous: true,
    }
  },

  {
    path: '/enrollment/faq',
    name: 'enrollmentfaq',
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/agent/EnrollmentFaq.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
    {
    path: "/enrolment/admin/dashboard",
    name: "EnrollmentAdminDashboard",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/enrollment/EnrollmentAdminDashboard.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/enrolment/admin/agent",
    name: "AgentList",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/enrollment/AgentList.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/enrolment/admin/agent/form",
    name: "AgentForm",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/enrollment/AgentForm.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/enrolment/admin/agent/edit/:uid",
    name: "AgentEdit",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/enrollment/AgentEdit.vue'),
    meta: { allowAnonymous: true, },
  },

  {
    path: "/enrolment/admin/manager",
    name: "ManagerList",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/enrollment/ManagerList.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/enrolment/admin/manager/form",
    name: "ManagerForm",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/enrollment/ManagerForm.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/enrolment/admin/manager/edit/:uid",
    name: "ManagerEdit",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/enrollment/ManagerEdit.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/enrolment/admin/profile",
    name: "EnrollmentAdminProfile",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/enrollment/EnrollmentAdminProfile.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/enrolment/admin/faq",
    name: "EnrollmentAdminFaq",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/enrollment/EnrollmentAdminFaq.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/enrolment/admin/faq/records",
    name: "EnrollmentAdminFaqList",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/enrollment/EnrollmentAdminFaqList.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/enrolment/admin/faq/edit/:uid",
    name: "EnrollmentAdminFaqEdit",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/enrollment/EnrollmentAdminFaqEdit.vue'),
    meta: { allowAnonymous: true, },
  },

  {
    path: '/enrollment/agent/profile',
    name: 'agentprofile',
    component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/agent/AgentProfile.vue'),
    meta: { allowAnonymous: true, }
  },
  {
    path: "/superuser/dashboard",
    name: "SuperUserDashboard",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/superuser/SuperUserDashboard.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/superuser/proflie",
    name: "SuperUserProfile",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/superuser/SuperUserProfile.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/superuser/records",
    name: "SuperUserList",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/superuser/SuperUserList.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/superuser/create",
    name: "SuperUserCreate",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/superuser/SuperUserCreate.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/superuser/edit/:uid",
    name: "SuperUserEdit",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/superuser/SuperUserEdit.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/superuser/enrollment/records",
    name: "EnrollmentAdminList",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/enrollment/EnrollmentAdminList.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/superuser/enrollment/create",
    name: "EnrollmentAdminCreate",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/enrollment/EnrollmentAdminCreate.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/superuser/enrollment/edit/:uid",
    name: "EnrollmentAdminEdit",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/enrollment/EnrollmentAdminEdit.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/superuser/clinical/records",
    name: "ClinicalAdminList",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/clinical/ClinicalAdminList.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/superuser/clinical/create",
    name: "ClinicalAdminCreate",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/clinical/ClinicalAdminCreate.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/superuser/clinical/edit/:uid",
    name: "ClinicalAdminEdit",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/clinical/ClinicalAdminEdit.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path:"/superuser/enrollment/manager/records",
    name:"EnrollmentManagerList",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/superuser/EnrollmentManagerList.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path:"/superuser/enrollment/manager/create",
    name:"EnrollmentManagerForm",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/superuser/EnrollmentManagerForm.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path:"/superuser/enrollment/manager/edit/:uid",
    name:"EnrollmentManagerEdit",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/superuser/EnrollmentManagerEdit.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path:"/superuser/enrollment/agent/records",
    name:"EnrollmentAgentList",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/superuser/EnrollmentAgentList.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path:"/superuser/enrollment/agent/create",
    name:"EnrollmentAgentForm",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/superuser/EnrollmentAgentForm.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path:"/superuser/enrollment/agent/edit/:uid",
    name:"EnrollmentAgentEdit",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/superuser/EnrollmentAgentEdit.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path:"/superuser/physician/admin/records",
    name:"PhysicianAdminList",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/physician/PhysicianAdminList.vue'),
    meta: { allowAnonymous: true, },
  },
  
  {
    path:"/superuser/allpatient/records",
    name:"AllPatient",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/superuser/AllPatient.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path:"/superuser/patient/view/:uid",
    name:"PatientView",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/superuser/PatientView.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path:"/superuser/patient/edit/:uid",
    name:"PatientEdit",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/superuser/PatientEdit.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path:"/superuser/physician/admin/create",
    name:"PhysicianAdminCreate",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/physician/PhysicianAdminCreate.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path:"/superuser/physician/admin/edit/:uid",
    name:"PhysicianAdminEdit",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/physician/PhysicianAdminEdit.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path:"/superuser/physicianNp/records",
    name:"PhysicianNpList",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/physician/PhysicianNpList.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path:"/superuser/physicianNp/create",
    name:"PhysicianNpCreate",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/physician/PhysicianNpCreate.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path:"/superuser/physicianNp/edit/:uid",
    name:"PhysicianNpEdit",
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/physician/PhysicianNpEdit.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: '/superuser/extension',
    name: 'AssignExtension',
    component: () => import(/* webpackChunkName: "about" */ '../views/administrator/superuser/AssignExtension.vue'),
    meta: { allowAnonymous: true, },
  },
  
 //Physician Admin
  {
    path: "/physician/admin/dashboard",
    name: "PhysicianAdminDashboard",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physician_admin/dashboard.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/admin/physicianlist",
    name: "PhysicianAdminListPhysician",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physician_admin/physicianList.vue'),
    meta: { allowAnonymous: true, },
  },

  {
    path: "/physician/admin/createphysician",
    name: "PhysicianAdminCreatePhysician",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physician_admin/physicianForm.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/admin/physicianedit/:user_uid",
    name: "PhysicianAdminEditPhysician",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physician_admin/physicianForm.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/admin/drassign",
    name: "PhysicianAdminAssignPhysician",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physician_admin/drassign.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/admin/completerx",
    name: "PhysicianAdminCompleteRx",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physician_admin/completeRx.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/admin/deniedrx",
    name: "PhysicianAdminDeniedRx",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physician_admin/deniedRx.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/admin/returntowatingroom",
    name: "PhysicianAdminReturnToWaitingRoom",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physician_admin/returnToWaitingRoom.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/admin/returntopatientassign",
    name: "PhysicianAdminReturnToPatientAssign",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physician_admin/returnToPatientAssign.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/admin/allpatients",
    name: "PhysicianAdminAllPatients",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physician_admin/allPatients.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/admin/editpatient/:uid",
    name: "PhysicianAdminEditPatient",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physician_admin/editPatient.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/admin/patientfile/:patient_uid",
    name: "PhysicianAdminPatientFile",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physician_admin/patientFile.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/admin/prescription",
    name: "PhysicianAdminPrescriptionPhysician",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physician_admin/prescription.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/admin/profile",
    name: "PhysicianAdminProfile",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physician_admin/physiciansAdminProfile.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/WaitingRoom",
    name: "WaitingRoom",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physicians/waitingRoom.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/completerx",
    name: "PhysicianCompleteRx",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physicians/completeRx.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/patientfile/:patient_uid",
    name: "PhysicianPatientFile",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physicians/patientFile.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/profile",
    name: "PhysicianProfile",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physicians/physiciansProfile.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/patientview/:patient_uid",
    name: "PhysicianPatientView",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/physicians/prescription.vue'),
    meta: { allowAnonymous: true, },
  },
  {
    path: "/physician/admin/test",
    name: "PhysicianAdminTest",
    component: () => import(/* webpackChunkName: "about" */ '../views/physician/Test.vue'),
    meta: { allowAnonymous: true, },
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
