<template>
  <div class="loginOuter">
    <div class="loginForm custom-form">
      <div class="logo-heaer">
        <img src="../assets/img/logo.png" />
      </div>
      <form class="loginField" @submit.prevent="submitForm">
        <div class="alert alert-danger" :class="{ 'alert-active': errorMessage != null }">
          <strong>Error!</strong> {{ errorMessage }}
        </div>
        <div class="inputCol">
          <label
            >Group Type
            <span class="text-danger error-text">
              * <span v-if="v$.usergroupuid.$error"> select an option </span>
            </span></label>
          <select v-model="formFields.usergroupuid"
            @change="selectGroup(formFields.usergroupuid)"
            class="custom-field"
            :class="{ errorCustomField: v$.usergroupuid.$error }"
          >
            <option
              v-for="(item, index) in userGroup"
              :key="index"
              v-bind:value="item.type_uid"
            >
              {{ item.type_user }}
            </option>
          </select>
        </div>
        <div class="inputCol">
          <label
            >User Type
            <span class="text-danger error-text">
              * <span v-if="v$.usertypeuid.$error"> select an option </span>
            </span></label>
          <select
            v-model="formFields.usertypeuid" class="custom-field"  :class="{ errorCustomField: v$.usertypeuid.$error }">
            <option
              v-for="(item, index) in userType"
              :key="index"
              v-bind:value="item.type_uid"
            >
              {{ item.type_user }}
            </option>
          </select>
        </div>
        <div class="inputCol">
          <label
            >Username
            <span class="text-danger error-text">
              *
              <span v-if="v$.user_username.$error"> username is required </span>
            </span></label
          >
          <input
            v-model="formFields.user_username"
            type="text"
            class="custom-field"
            :class="{ errorCustomField: v$.user_username.$error }"
          />
        </div>
        <div class="inputCol">
          <label
            >Password
            <span class="text-danger error-text">
              *
              <span v-if="v$.user_password.$error"> password is required </span>
            </span></label
          >
          <input
            v-model="formFields.user_password"
            type="password"
            class="custom-field"
            :class="{ errorCustomField: v$.user_password.$error }"
          />
        </div>
        <!-- <div class="inputCol text-end">
          <a href="#">Forgot Your Password</a>
        </div> -->
        <div class="button-outer inputCol">
          <button class="custom-button">Login</button>
        </div>
        <!-- <div class="inputCol text-center">
          <a class="custom-link" @click="authFB">
                    Login with Facebook
                </a>
         </div> -->
            
      </form>
    </div>
  </div>
</template>
<script>
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import callService from "@/services/Service";
const { getData, getByID, postData } = callService();
export default {
  name: "Login",
  data() {
    return {
      userGroup: [],
      userType: [],
      errorMessage: null,
      successMessage: null,
    };
  },
  setup() {
    const formFields = reactive({
      usergroupuid: "",
      usertypeuid: "",
      user_username: "",
      user_password: "",
    });

    const rules = {
      usergroupuid: {
        required,
      },
      usertypeuid: {
        required,
      },
      user_username: {
        required,
      },
      user_password: {
        required,
      },
    };
    const v$ = useVuelidate(rules, formFields);
    return {
      formFields,
      v$,
    };
  },
  async created() {
    if (localStorage.getItem("LogIn")) {
      const type_uid = JSON.parse(localStorage.getItem("LogIn")).type_uid;
      const redirction = type_uid === "ipg4bi4v10" ? "enrollmentform" : "login";
      this.$router.push({
        name: redirction,
      });
    }

    try {
      const resData = await getData("login/getUserGroup");
      if (resData.status === 200 && resData.data.statuscode === 1) {
        this.userGroup = resData.data.result;
      }
    } catch (e) {
      this.error = e;
    }
  },
  methods: {
    async selectGroup(uid) {
      console.log("uid", uid);
      try {
        const resData = await getByID("login/getUserById/", uid);
        if (resData.status === 200 && resData.data.statuscode === 1) {
          this.userType = resData.data.result;
        }
      } catch (e) {
        this.error = e;
      }
    },
    hideError: function () {
      this.errorMessage = null;
    },
    authFB(){
      this.$router.push({name: "Enrollment"});
    },
    async submitForm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          const resData = await postData("login/userLogin", this.formFields);
          if (resData.status === 200 && resData.data.statuscode === 1) {            
            if (resData.data.result.extension !== "") {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              
              // Dialer Session Values
              //const ext = "3002";
              
              localStorage.setItem("ServerPath", "/ws");
              localStorage.setItem("EchoCancellation", "1");
              localStorage.setItem("VoiceMailSubscribe", "1");
              localStorage.setItem("SipDomain", "dailer.caretalkhosting.com");
              localStorage.setItem("profileName", resData.data.result.extension);
              localStorage.setItem("SipUsername", resData.data.result.extension);
              localStorage.setItem("AspectRatio", "1.33");
              localStorage.setItem("VideoSrcId", "default");
              localStorage.setItem("SipPassword", "SIP123ext");
              localStorage.setItem("loglevel:webpack-dev-server", "WARN");
              localStorage.setItem("AutoGainControl", "1");
              localStorage.setItem("wssServer", "dailer.caretalkhosting.com");
              localStorage.setItem("RingOutputId","724c6dad96e08e09da3ed3fb21ed79a3966a0e916eb4a267d108a02ae19ea4ee");
              localStorage.setItem("WebSocketPort", "8089");
              localStorage.setItem("NoiseSuppression", "1");
              localStorage.setItem("language", "en");
              localStorage.setItem("WelcomeScreenAccept", "yes");
              localStorage.setItem("InstanceId", String(Date.now()));
              localStorage.setItem("16750799442502185-Buddies",'{"TotalRows":0,"DataCollection":[]}');
              localStorage.setItem("profileUserID", "167508055739511B1");
              localStorage.setItem("ChatEngine", "SIMPLE");
              localStorage.setItem("AudioOutputId", "default");
              localStorage.setItem("AudioSrcId", "default");
              localStorage.setItem("VideoOrientation", "rotateY(180deg)");
              localStorage.setItem("profileVcard",'{"TitleDesc":"","Mobile":"","Email":"","Number1":"","Number2":""}');
              localStorage.setItem("Notifications", "0");
              localStorage.setItem("167508055739511B1-Buddies",'{"TotalRows":0,"DataCollection":[]}');              
                                
            }else{
              setTimeout(function() {
                ShowContacts();
              }, 3000);
              
            }
            // SuperUser
            if (resData.data.result.type_uid === "ipg4bi4vg5") {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              this.$router.push({
                name: "SuperUserDashboard",
              });
            }
            // Enrollment Admin
            if (resData.data.result.type_uid === "ipg4bi4vg6") {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              this.$router.push({
                name: "EnrollmentAdminDashboard",
              });
            }
            // Clinical Admin
            if (resData.data.result.type_uid === "ipg4bi4vg7") {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              this.$router.push({
                name: "ClinicalAdminDashboard",
              });
            }
            //General Admin
            if (resData.data.result.type_uid === "ipg4bi4vg8") {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              this.$router.push({
                name: "GeneralAdminDashboard",
              });
            }
            //Enrollment Manager
            if (resData.data.result.type_uid === "ipg4bi4vg9") {
              localStorage.setItem("LogIn",JSON.stringify(resData.data.result));
              this.$router.push({ name: "EnrollmentManagerDashboard" });
            }
            //Enrollment Agent
            if (resData.data.result.type_uid === "ipg4bi4v10") {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              this.$router.push({ name: "enrollmentform" });
            }
            //Physician Admin
            if (resData.data.result.type_uid === "ipg4bi4v11") {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              this.$router.push({
                name: "PhysicianAdminDashboard",
              });
            }
            //Physician
            if (resData.data.result.type_uid === "ipg4bi4v12") {
              localStorage.setItem("LogIn",JSON.stringify(resData.data.result));
              // this.updateCallStatus(resData.data.result.user_uid);
              this.$router.push({ name: "WaitingRoom",});
            }
          } else {
            this.$router.push("/");
            this.errorMessage = resData.data.message;
            setTimeout(
              function () {
                this.hideError();
              }.bind(this),
              4000
            );
          }
        } catch (e) {
          this.error = e;
        }
      }
    },
  },
};
</script>
<style scoped>
.hClass {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #999393;
  line-height: 0.1em;
  margin: 18px 0 22px;
}

.hClass span {
  background: #fff;
  padding: 0 10px;
}
</style>